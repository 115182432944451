import { Component, Vue } from 'nuxt-property-decorator';
import PdfViewer from '@/components/viewer/pdf-viewer.vue';
import WebViewer from '@/components/viewer/web-viewer.vue';
import ErrorViewer from '@/components/viewer/error-viewer.vue';
import { Nullable } from '@/types/nullable';
import BinderItemCard from '@/components/binder-item-card.vue';
import LayoutFoldable from '@/components/base/layout-foldable.vue';
import OrderSelector from '@/components/common/order-selector.vue';

import { highlightColorName, sorterDocTitle } from '@/utils/binderFoldersUtils';
import { byKeySorter } from '@/utils/arrayUtil';
import { OrderType } from '@/components/common/order-selector.vue';
import { NonPermitDocumentError } from 'wklr-backend-sdk/errors';
import { BinderItem } from '@/types/binder-folders';
import { Binder, BookmarkViewTypeEnumPdf, BookmarkViewTypeEnumWeb, DocRecord, Folder } from 'wklr-backend-sdk/models';

type OrderVariant = 'updatedAt-asc' | 'updatedAt-desc' | 'docname-asc';

const BINDER_ORDER_MAP: OrderType<OrderVariant>[] = [
  {
    label: '更新日の新しい順',
    value: 'updatedAt-desc',
  },
  {
    label: '更新日の古い順',
    value: 'updatedAt-asc',
  },
  {
    label: '文書の名前順（文字コード）',
    value: 'docname-asc',
  },
];

const DEFAULT_BINDER_ORDER = BINDER_ORDER_MAP[0];

const sorterUpdatedAt = byKeySorter<BinderItem>('updatedAt');
@Component({
  components: {
    BinderItemCard,
    LayoutFoldable,
    OrderSelector,
    PdfViewer,
    WebViewer,
    ErrorViewer,
  },
})
export default class BinderBase extends Vue {
  $refs!: {
    webViewer: WebViewer;
    pdfViewer: PdfViewer;
  };

  id!: string;

  record: Nullable<DocRecord> = null;

  /** 文献の閲覧権限が無い場合はエラー（true）になる */
  isPermissionError = false;

  initialPage: Nullable<number> = null;

  initialKey: Nullable<number> = null;

  initialSeries: Nullable<number> = null;

  selectedBinderId: Nullable<string> = null;

  folder: Folder | undefined;

  binder!: Binder;

  items!: BinderItem[];

  viewerType: 'web' | 'pdf' | 'error' = 'web';

  get orderdItems(): BinderItem[] {
    const items = [...this.items];
    switch (this.binderOrder.value) {
      case 'updatedAt-desc':
        items.sort(sorterUpdatedAt).reverse();
        break;
      case 'updatedAt-asc':
        items.sort(sorterUpdatedAt);
        break;
      case 'docname-asc':
        items.sort(sorterDocTitle);
        break;
    }
    return items;
  }

  // 現在選択されているハイライト情報
  // highlight: Nullable<Highlight> = null;

  /** バインダー内の並び順のモデル */
  binderOrder = DEFAULT_BINDER_ORDER;
  readonly binderOrderMap = BINDER_ORDER_MAP;

  get folderName(): string {
    return this.folder !== undefined ? this.folder.name : '';
  }

  get highlightColorClass(): string {
    return `-${highlightColorName(this.binder.highlightColor)}`;
  }

  // TODO: リファクタ。 selectedBinderId みたいな値を持っておいてあとは computed で算出したい
  async binderSelectHandler(item: BinderItem): Promise<void> {
    if (this.selectedBinderId === item.id) return;
    this.selectedBinderId = item.id;
    this.record = null;

    switch (item.type) {
      case 'highlight':
        // this.highlight = item.content;
        // this.bookmark = null;
        this.viewerType = BookmarkViewTypeEnumWeb.Web;
        this.initialKey = item.key;
        this.initialSeries = item.highlightedContents[0].series; // FIXME: ハイライトは複数の content にまたがるが強調はひとつしかできないので見た目に不整合が発生している
        this.$telemetry.sendClickTelemetry({ button: 'binder__highlight' }, this.$route);
        break;
      case 'bookmark':
        // this.highlight = null;
        // this.bookmark = item.content;
        if (item.viewType === BookmarkViewTypeEnumWeb.Web) {
          this.viewerType = BookmarkViewTypeEnumWeb.Web;
          this.initialPage = null;
          this.initialKey = item.key;
          this.initialSeries = null;
        } else {
          this.viewerType = BookmarkViewTypeEnumPdf.Pdf;
          this.initialPage = item.pageSeq + 1;
          this.initialKey = null;
          this.initialSeries = null;
        }
        this.$telemetry.sendClickTelemetry({ button: 'binder__bookmark' }, this.$route);
        break;
    }

    this.$nextTick(async () => {
      try {
        const record = await this.$repositories.docs.get(item.doc.id);
        this.record = record;
        this.isPermissionError = false;
      } catch (err) {
        if (err instanceof NonPermitDocumentError) {
          this.record = err.record;
          this.isPermissionError = true;
          this.viewerType = 'error';
          this.initialPage = null;
          this.initialKey = null;
          this.initialSeries = null;
        } else {
          console.error(err);
          throw err;
        }
      }
    });
  }

  clearFind(): void {
    this.$store.commit('clearFind');
  }

  updateInViewSections(): void {
    // NOP
  }

  navigationToDocument(e: KeyboardEvent | MouseEvent): void {
    let documentPath: string;
    if (this.viewerType === 'web') {
      if (!this.$refs.webViewer) {
        return;
      }

      documentPath = this.$refs.webViewer.inViewContentUrl;
    } else {
      if (!this.$refs.pdfViewer) {
        return;
      }

      documentPath = this.$refs.pdfViewer.inViewContentUrl;
    }

    if (e && (e.ctrlKey || e.metaKey)) {
      window.open(documentPath, '_blank');
      return;
    }
    window.open(documentPath, '_blank');
  }
}
