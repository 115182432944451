<template>
  <v-container class="foldable-layout foldable-renewal" :class="layoutClass">
    <v-row>
      <v-col :cols="isFold ? mainCols + foldCols : mainCols" class="pa-0">
        <v-container class="main-container">
          <slot name="main">
            <!-- メインコンテンツが入る -->
          </slot>
          <site-footer />
        </v-container>
      </v-col>
      <v-col v-if="!isFold" ref="fold-wrapper" :cols="foldCols" class="pa-0">
        <section ref="scroll-container" class="fold-container">
          <slot name="fold" :scroll-container="$refs['scroll-container']">
            <!-- フォールドのコンテンツが入る -->
          </slot>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import MyFooter from '@/components/base/my-footer.vue';
import SiteFooter from '@/components/renewal/footer/site-footer.vue';

/**
 * 基本は 100% 幅だが、必要に応じてフォールド部分を表示することができるレイアウト
 */
@Component({ components: { MyFooter, SiteFooter } })
export default class LayoutFoldable extends Vue {
  /** フォールド部分が非表示になっているか */
  @Prop() isFold!: boolean;

  /** フォールドを表示した時のメイン部分の幅 */
  @Prop({ default: 6 }) mainCols!: number;

  /** フォールドを表示した時のフォールド部分の幅 */
  @Prop({ default: 6 }) foldCols!: number;

  /** 画面幅が 100% かどうか */
  @Prop({ default: true }) fullWidth!: boolean;

  /** レイアウトに指定するスタイル */
  get layoutClass(): string[] {
    const layoutClass = [];
    if (this.fullWidth) {
      layoutClass.push('-fullWidth');
    }
    return layoutClass;
  }
}
</script>

<style lang="scss" scoped>
@import '../../layouts/default.scss';

.foldable-layout,
.main-container {
  --content-height: calc(100vh - #{$global-header-height});
  height: var(--content-height) !important;

  .fold-container {
    height: var(--content-height) !important;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .foldable-layout,
  .main-container {
    --content-height: calc(100vh - #{$global-header-height-sm-xs});
    height: var(--content-height) !important;
    .fold-container {
      height: var(--content-height) !important;
    }
  }
}

@media (800px <= width) {
  .foldable-layout.foldable-renewal,
  .foldable-renewal .main-container {
    --content-height: calc(100vh - var(--header-height-lg));
    height: var(--content-height) !important;
    .fold-container {
      height: var(--content-height) !important;
    }
  }
}
@media (600px <= width < 800px) {
  .foldable-layout.foldable-renewal,
  .foldable-renewal .main-container {
    --content-height: calc(100vh - var(--header-height-md));
    height: var(--content-height) !important;
    .fold-container {
      height: var(--content-height) !important;
    }
  }
}
@media (width < 600px) {
  .foldable-layout.foldable-renewal,
  .foldable-renewal .main-container {
    --content-height: calc(100vh - var(--header-height-sm));
    height: var(--content-height) !important;
    .fold-container {
      height: var(--content-height) !important;
    }
  }
}

.foldable-layout,
.main-container {
  width: 100%;
  padding: 0 12px;
  overflow-y: scroll;
}
.fold-container {
  overflow-y: scroll;
}
.foldable-layout {
  // Vuetify のスタイルを上書き
  .row {
    flex: none;
    margin: 0 -12px;
  }
  // Vuetify のスタイルを上書き

  .main-container {
    padding: 0 12px;
  }

  &.-fullWidth {
    max-width: 100%;

    .main-container {
      max-width: 100%;
    }
  }
}
</style>
